<template>
  <div id="page-grants">
    <h5 class="m-4">Fortnox Fakturor</h5>
    
    <b-input-group size="sm" class="mb-4">
      <b-form-input
        v-model="search"
        type="search"
        style="max-width: 380px;"
        placeholder="Sök faktura"
      />
      <b-input-group-prepend is-text>
        <inline-svg src="/assets/svg/search.svg"/>
      </b-input-group-prepend>
    </b-input-group>

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </b-col>
    </b-row>

    <b-table
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      head-variant="light"
      :fields="invoice_headers"
      :items="invoices"
      :per-page="per_page"
      :current-page="current_page"
    >
      <template #cell(action)="{ item }">
        <b-button
          type="button"
          class="btn btn-sm"
          @click="select_fortnox_invoice_clicked(item.DocumentNumber)"
          variant="primary"
        >
          Länk
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'fortnox-invoices',
  components: {

  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'sid'])
  },
  mixins: [ toasts ],
  mounted() {
    this.isTopCompany = this.sid === this.currentCompanyId;
    this.get_invoices();
  },
  data() {
    return {
      headerProps: {
        sortByText: 'Sortera'
      },
      search: '',
      rowsPerPage: [10, 20, 40, -1],
      isTopCompany: false,
      invoices: [],
      invoice_headers: [
        { label: 'Namn', key: 'CustomerName' },
        { label: 'Fakturanummer', key: 'DocumentNumber' },
        { label: 'Skapad', key: 'InvoiceDate' },
        { label: 'Hantera', key: 'action' }
      ],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
    };
  },
  watch: {
    invoices: {
      handler() {
        this.total_rows = this.invoices.length;
      },
      deep: true
    },
  },
  methods: {
    async get_invoices() {
      try {
        const res = await axios.get(`/fortnox/invoices`)
        this.invoices = res.data.Invoices;
      } catch(err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista fakturor');
      }
    },
    select_fortnox_invoice_clicked(DocumentNumber) {

    },
  }
};
</script>

<style scoped lang="scss">

</style>
